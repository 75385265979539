import { map, orderBy } from "lodash";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import {
  fetchPaymentTransactions,
  unfetchPaymentTransactions,
  postSinglePayment,
} from "./../slices/payments";

const PaymentSummaryGrid = () => {
  const dispatch = useDispatch();
  const { payments, loading, error } = useSelector((state) => state.payments);

  useEffect(() => {
    // Fetch payment transactions when the component mounts
    dispatch(fetchPaymentTransactions());
    return () => dispatch(unfetchPaymentTransactions());
  }, [dispatch]);

  const columns = [
    { field: "id", headerName: "Transaction ID", width: 250 },
    { field: "receiptNumber", headerName: "Receipt Number", width: 150 },
    { field: "status", headerName: "Status", width: 120 },
    { field: "amount", headerName: "Amount", width: 100 },
    { field: "currency", headerName: "Currency", width: 100 },
    { field: "customerId", headerName: "Customer ID", width: 250 },
    { field: "name", headerName: "Name", width: 250 },
    {
      field: "receiptUrl",
      headerName: "Receipt Link",
      width: 200,
      renderCell: (params) => (
        <a href={params.value} target="_blank" rel="noopener noreferrer">
          View Receipt
        </a>
      ),
    },
    {
      field: "posted",
      headerName: "Post",
      width: 200,
      renderCell: (params) => (
        <button
          disabled={params.value}
          onClick={() => dispatch(postSinglePayment(params.row?.id))}
        >
          {params.value ? "Posted" : "Post"}
        </button>
      ),
    },
  ];

  // Map and sort payments by the most recent `createdAt`
  const rows = orderBy(
    map(payments, (payment, key) => ({
      id: key,
      receiptNumber: payment.receiptNumber,
      status: payment.status,
      amount: payment.totalMoney?.amount / 100, // Convert cents to dollars if applicable
      currency: payment.totalMoney?.currency,
      customerId: payment.customerId,
      receiptUrl: payment.receiptUrl, // Add receipt URL
      createdAt: new Date(payment.createdAt), // Parse `createdAt` for sorting
      name: payment.firstName ? `${payment.firstName} ${payment.lastName}` : "",
      posted: payment.posted,
    })),
    ["createdAt"], // Sort by createdAt field
    ["desc"], // Descending order
  );

  const getDetailPanelContent = (params) => {
    const payment = payments[params.id];
    if (!payment) return null;

    return (
      <div style={{ padding: 20 }}>
        <div>
          <strong>Card Details:</strong>
          <p>Brand: {payment.cardDetails?.card?.cardBrand}</p>
          <p>Last 4: {payment.cardDetails?.card?.last4}</p>
          <p>
            Expiry: {payment.cardDetails?.card?.expMonth}/
            {payment.cardDetails?.card?.expYear}
          </p>
          <p>Status: {payment.cardDetails?.status}</p>
        </div>
        <div>
          <strong>Processing Fee:</strong>
          <p>
            {payment.processingFee?.[0]?.amountMoney?.amount / 100}{" "}
            {payment.processingFee?.[0]?.amountMoney?.currency}
          </p>
          <strong>Timeline:</strong>
          <p>Created At: {payment.createdAt}</p>
          <p>
            Captured At: {payment.cardDetails?.cardPaymentTimeline?.capturedAt}
          </p>
        </div>
      </div>
    );
  };

  const getDetailPanelHeight = () => 200;

  return (
    <div style={{ height: "calc(90vh - 40px)", width: "100%" }}>
      {loading && <p>Loading...</p>}
      {error && <p>Error: {error}</p>}
      <DataGridPremium
        rows={rows}
        columns={columns}
        disableSelectionOnClick
        getDetailPanelContent={getDetailPanelContent}
        getDetailPanelHeight={getDetailPanelHeight}
      />
    </div>
  );
};

export default PaymentSummaryGrid;
