import { round, find, map } from "lodash";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Typography,
  Button,
  Grid,
  Paper,
  Divider,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  TextField,
  InputAdornment,
  Box,
  Card,
  CardContent,
  useMediaQuery,
  useTheme,
  Link,
  CircularProgress,
} from "@mui/material";
// import CreateIcon from "@mui/icons-material/Create";
import { styled } from "@mui/system";
import { green, orange } from "@mui/material/colors";
import {
  linkAccountToUser,
  fetchUserAccount,
  fetchRecentTransactions,
  fetchTransactions,
} from "./../slices/user";
import LinkAccountDialog from "./LinkAccountDialog";
import PaymentDialog from "./PaymentDialog";

import { fetchUserCards } from "./../slices/user";
import { fetchPaymentTransactionsByCustomer } from "./../slices/payments";

// Styled container for horizontal scroll
const ScrollContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  overflowX: "auto",
  padding: theme.spacing(2),
  gap: theme.spacing(2),
  [theme.breakpoints.up("md")]: {
    overflowX: "hidden", // Hide scroll on larger screens
  },
}));

function PricingPlan({ balance, autopayOn, autopayPending }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { loading } = useSelector((state) => state.user);
  const [paymentAmount, setpaymentAmount] = React.useState(balance);
  const [customAmount, setCustomAmount] = React.useState(0);
  const [note, setNote] = React.useState(null);

  const handleOpen = (note) => setNote(note);
  const handleClose = () => setNote(null);

  const formatBalance = (cents) => {
    return (cents / 100).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  };

  const due = React.useMemo(() => formatBalance(balance), [balance]);

  const updatePayment = (e) => {
    const { value } = e.target;
    setCustomAmount(value);
  };

  const plans = [
    {
      title: "Single Payment",
      price: due,
      description: "Pay total amount due and bring account up to date",
      button: "Pay Now",
    },
    {
      title: "Monthly Autopay",
      price: "$15/mo",
      description:
        autopayOn || autopayPending ? (
          <Box>
            Current Autopay Status:{" "}
            <Typography
              sx={{ color: autopayOn ? green[500] : orange[500] }}
              component="span"
            >
              {autopayPending ? "PENDING" : "ACTIVE"}
            </Typography>{" "}
            <br />
            <Link onClick={() => navigate("/profile")}>See Details</Link>
          </Box>
        ) : (
          "Setup automatically reoccuring monthly payments"
        ),
      button: loading ? (
        <CircularProgress color="info" size={24} sx={{ ml: 2, mr: 2 }} />
      ) : autopayOn || autopayPending ? (
        "Disable AutoPay"
      ) : (
        "Enable AutoPay"
      ),
    },
    {
      title: "Custom Amount",
      price: (
        <TextField
          value={customAmount}
          type="number"
          onChange={updatePayment}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          inputProps={{
            step: "0.01",
          }}
          sx={{
            mr: 2,
            "& input": {
              pt: 1,
              pb: 1,
              pr: 2,
              textAlign: "right",
            },
          }}
        />
      ),
      description: "Select a custom dollar amount to submit as payment",
      button: "Pay Now",
    },
  ];

  return (
    <ScrollContainer>
      {plans.map((plan, index) => (
        <Card
          key={index}
          sx={{ minWidth: isMobile ? 200 : 265, maxWidth: 265 }}
        >
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              alignContent: "space-between",
              justifyContent: "center",
              flexWrap: "wrap",
              height: "100%",
            }}
          >
            <Typography variant="h6" align="center">
              {plan.title}
            </Typography>
            <Typography
              variant="h4"
              align="center"
              color="primary"
              sx={{ margin: theme.spacing(2, 0) }}
            >
              {plan.price}
            </Typography>
            <Typography
              variant="body2"
              component="div"
              align="center"
              color="textSecondary"
              sx={{ marginBottom: theme.spacing(2) }}
            >
              {plan.description}
            </Typography>
            <Box display="flex" justifyContent="center">
              <Button
                variant="contained"
                color="primary"
                disabled={loading}
                onClick={() => handleOpen(plan.title)}
              >
                {plan.button}
              </Button>
            </Box>
          </CardContent>
        </Card>
      ))}
      <PaymentDialog
        paymentAmount={
          customAmount > 0 && note === "Custom Amount"
            ? parseFloat((customAmount * 100).toFixed(2))
            : paymentAmount
        }
        note={note}
        onClose={handleClose}
        amountDue={balance}
      />
    </ScrollContainer>
  );
}

const UserPage = () => {
  const dispatch = useDispatch();
  const [pay, setPay] = useState(0);
  const [editAmount, setEditAmount] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const {
    linkedAccount,
    // transactions: recentTransactions,
    subscriptions,
  } = useSelector((state) => state.user);
  const { payments: recentTransactions } = useSelector(
    (state) => state.payments,
  );
  const [dialogOpen, setDialogOpen] = useState(false);
  const squareCustomerId = user?.squareCustomerId;

  useEffect(() => {
    if (linkedAccount?.amountDue) {
      setPay(round(linkedAccount.amountDue / 100, 2));
    }
  }, [linkedAccount]);
  useEffect(() => {
    if (user) {
      dispatch(fetchUserAccount(user.uid));
      dispatch(fetchRecentTransactions(user.uid));
      dispatch(fetchPaymentTransactionsByCustomer(squareCustomerId));
      dispatch(fetchTransactions());
    }
  }, [dispatch, user]);

  const handleOpenDialog = () => setDialogOpen(true);
  const handleCloseDialog = () => setDialogOpen(false);
  const updatePayment = (e) => {
    const { value } = e.target;
    setPay(value);
  };
  const handleLinkAccount = (accountId) => {
    dispatch(linkAccountToUser({ userId: user.uid, accountId }));
    handleCloseDialog();
  };

  const formatDate = (timestamp) => {
    return new Date(timestamp).toLocaleDateString();
  };

  const formatBalance = (cents) => {
    return (cents / 100).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  };

  const toggleAmount = () => setEditAmount((p) => !p);

  useEffect(() => {
    if (squareCustomerId) {
      dispatch(fetchUserCards(squareCustomerId));
    }
  }, [dispatch, squareCustomerId]);

  return (
    <Paper
      elevation={0}
      sx={{
        borderRadius: 2,
        textAlign: "center",
        maxWidth: (t) => t.breakpoints.values.md + 20,
        margin: (t) => `${t.spacing(2)} auto`,
      }}
    >
      <Grid container sx={{ p: 2 }}>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Account Balance:{" "}
            <Box
              component="span"
              sx={(theme) => ({
                color:
                  linkedAccount?.credit - linkedAccount?.amountDue < 0
                    ? theme.palette.error.light
                    : theme.palette.text.primary,
              })}
            >
              {formatBalance(linkedAccount?.credit - linkedAccount?.amountDue)}
            </Box>
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={(t) => ({
            borderRadius: `${t.spacing(2)} ${t.spacing(2)} 0px 0px `,
            backgroundColor: t.palette.primary.main,
            color: t.palette.primary.contrastText,
            // mb: 2,
            p: 2,
          })}
        >
          {linkedAccount ? (
            <PricingPlan
              balance={linkedAccount.amountDue}
              autopayOn={find(subscriptions, (s) => s.status === "ACTIVE")}
              autopayPending={find(
                subscriptions,
                (s) => s.status === "PENDING",
              )}
            />
          ) : (
            <>
              <Typography variant="h6">No account linked</Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={handleOpenDialog}
              >
                Link Account
              </Button>
            </>
          )}
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <Typography variant="h5" gutterBottom>
              Recent Transactions
            </Typography>
            <Grid container sx={{ fontWeight: "bold", py: 1 }}>
              <Grid item xs={3}>
                Date
              </Grid>
              <Grid item xs={5}>
                Description
              </Grid>
              <Grid item xs={2}>
                Status
              </Grid>
              <Grid item xs={2} sx={{ textAlign: "right" }}>
                Amount
              </Grid>
            </Grid>
            <Divider />
            {map(recentTransactions, (transaction) => (
              <React.Fragment key={transaction.id}>
                <Grid container sx={{ py: 1 }}>
                  <Grid item xs={3}>
                    {formatDate(transaction.createdAt)}
                  </Grid>
                  <Grid item xs={5}>
                    {transaction.note}
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    sx={{
                      fontSize: "0.7rem",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                      color:
                        transaction.status === "COMPLETED"
                          ? green[500]
                          : orange[500],
                    }}
                  >
                    {transaction.status}
                  </Grid>
                  <Grid item xs={2} sx={{ textAlign: "right" }}>
                    {formatBalance(transaction.amountMoney?.amount)}
                  </Grid>
                </Grid>
                <Divider />
              </React.Fragment>
            ))}
          </Paper>
        </Grid>
        <LinkAccountDialog
          open={dialogOpen}
          onClose={handleCloseDialog}
          onLink={handleLinkAccount}
        />
        <Dialog open={editAmount} onClose={toggleAmount}>
          <DialogTitle>Edit Payment Amount</DialogTitle>
          <DialogContent>
            <TextField
              value={pay}
              type="number"
              onChange={updatePayment}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              inputProps={{
                step: "0.01",
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={toggleAmount}>Close</Button>
            <Button onClick={toggleAmount} variant="outlined">
              Update
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </Paper>
  );
};

export default UserPage;
