import { find } from "lodash";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  InputAdornment,
} from "@mui/material";

import { addAccount, editAccount } from "./../slices/accounts";

const initialValues = {
  houseNumber: "",
  propertyAddress: "Olivia Dr.",
  accountBalance: 0,
  amountDue: 0,
  credit: 0,
  linked: false,
};

const AccountDialog = ({ open, onClose, editId }) => {
  const dispatch = useDispatch();
  const { accounts, loading, error } = useSelector((state) => state.accounts);
  const editAccountData = find(accounts, (a) => a.id === editId);
  const [formData, setFormData] = useState(initialValues);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
      accountBalance:
        name === "amountDue"
          ? prevData.credit - value
          : value - prevData.amountDue,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      editAccountData
        ? editAccount({
            ...formData,
            houseNumber: formData.houseNumber,
            propertyAddress: formData.propertyAddress,
            accountBalance: Math.round(
              parseFloat(formData.accountBalance) * 100,
            ),
            amountDue: Math.round(parseFloat(formData.amountDue) * 100),
            credit: Math.round(parseFloat(formData.credit) * 100),
          })
        : addAccount({
            houseNumber: formData.houseNumber,
            propertyAddress: formData.propertyAddress,
            accountBalance: Math.round(
              parseFloat(formData.accountBalance) * 100,
            ),
            amountDue: Math.round(parseFloat(formData.amountDue) * 100),
            credit: Math.round(parseFloat(formData.credit) * 100),
            linked: false,
          }),
    );
    onClose();
    setFormData(initialValues);
  };

  useEffect(() => {
    const formattedData = (data) => {
      const credit = parseFloat((data.credit / 100).toFixed(2));
      const amountDue = parseFloat((data.amountDue / 100).toFixed(2));
      const accountBalance = parseFloat(
        ((data.credit - data.amountDue) / 100).toFixed(2),
      );
      return {
        ...data,
        accountBalance,
        credit,
        amountDue,
      };
    };
    setFormData(
      editAccountData ? formattedData(editAccountData) : initialValues,
    );
  }, [editId]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add New Account</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="houseNumber"
            label="House Number"
            type="text"
            fullWidth
            variant="outlined"
            value={formData.houseNumber}
            onChange={handleChange}
            required
          />
          <TextField
            margin="dense"
            name="propertyAddress"
            label="Property Address"
            type="text"
            fullWidth
            variant="outlined"
            value={formData.propertyAddress}
            onChange={handleChange}
            required
          />
          <TextField
            margin="dense"
            name="credit"
            label="Account Credit"
            type="number"
            fullWidth
            variant="outlined"
            value={formData.credit}
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            inputProps={{
              step: "0.01",
            }}
            required
          />
          <TextField
            margin="dense"
            name="amountDue"
            label="Amount Due"
            type="number"
            fullWidth
            variant="outlined"
            value={formData.amountDue}
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            inputProps={{
              step: "0.01",
            }}
            required
          />
          <TextField
            margin="dense"
            name="accountBalance"
            label="Account Balance"
            type="number"
            fullWidth
            variant="outlined"
            disabled
            value={formData.accountBalance}
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            inputProps={{
              step: "0.01",
            }}
            required
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit" variant="contained" color="primary">
            {editId ? "update Account" : "Create Account"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AccountDialog;
