/*global fetch */
import { mapValues, find, has } from "lodash";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  ref,
  off,
  get,
  onValue,
  query,
  equalTo,
  orderByChild,
} from "firebase/database";
import { httpsCallable } from "firebase/functions";

import { db, auth } from "./../firebase_config";
import { enqueueSnackbar } from "./notifications";
import { fetchRecentTransactions } from "./user";

export const processSquarePayment = createAsyncThunk(
  "payment/processSquarePayment",
  async (
    { sourceId, amount, currency, locationId, idToken, note, customerId },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await fetch(
        "https://us-central1-shelby-estates-hoa.cloudfunctions.net/processSquarePayment",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
          body: JSON.stringify({
            sourceId,
            amount,
            currency,
            locationId,
            customerId,
            note,
          }),
        },
      );
      const data = await response.json();
      if (response.ok && data.success) {
        // await push(ref(db, `transactions/${data.userId}`), {
        //   ...data,
        // });
        dispatch(fetchRecentTransactions(data.userId));
        return data;
      } else {
        return rejectWithValue(data.error || "Payment processing failed");
      }
    } catch (error) {
      console.error("Error in processSquarePayment:", error);
      return rejectWithValue(
        "An unexpected error occurred while processing your payment",
      );
    }
  },
);

export const postSinglePayment = createAsyncThunk(
  "payment/postSinglePayment",
  async (transactionId, { dispatch, rejectWithValue }) => {
    try {
      const user = auth.currentUser;
      const idToken = await user.getIdToken();
      const response = await fetch(
        "https://us-central1-shelby-estates-hoa.cloudfunctions.net/postSinglePayment",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
          body: JSON.stringify({
            transactionId,
          }),
        },
      );
      const data = await response.json();
      if (response.ok && data.success) {
        console.log(data);
        return data;
      } else {
        return rejectWithValue(data.error || "Payment posting failed");
      }
    } catch (error) {
      console.error("Error in postSinglePayment:", error);
      return rejectWithValue(
        "An unexpected error occurred while posting your payment",
      );
    }
  },
);

export const fetchPaymentTransactionsByCustomer = createAsyncThunk(
  "payment/fetchPaymentTransactionsByCustomer",
  async (customerId, { dispatch, rejectWithValue }) => {
    try {
      // const users = await get(ref(db, "users")).then((s) => s.val());
      // const userAccounts = await get(ref(db, "userAccounts")).then((s) =>
      //   s.val(),
      // );
      // const accounts = await get(ref(db, "accounts")).then((s) => s.val());
      const sqRef = ref(db, "squareTransactions");
      await onValue(
        query(sqRef, orderByChild("customerId"), equalTo(customerId)),
        (snapshot) => {
          const txns = snapshot.val();
          return dispatch(listPayments(txns));
        },
      );
    } catch (error) {
      console.error("Error in fetchinSquarePaymentsByCustomer:", error);
    }
  },
);

export const fetchPaymentTransactions = createAsyncThunk(
  "payment/fetchPaymentTransactions",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const user = auth.currentUser;
      const idToken = await user.getIdToken();
      fetch(
        "https://us-central1-shelby-estates-hoa.cloudfunctions.net/fetchAllTransactions",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
        },
      ).then((response) => {
        response.json().then((d) => console.log(d));
      });
      // const data = await res.json();
      // console.log(data);
      const users = await get(ref(db, "users")).then((s) => s.val());
      const userAccounts = await get(ref(db, "userAccounts")).then((s) =>
        s.val(),
      );
      const accounts = await get(ref(db, "accounts")).then((s) => s.val());
      const postedPayments = await get(ref(db, `postedPayments`)).then((s) =>
        s.val(),
      );

      await onValue(ref(db, "squareTransactions"), (snapshot) => {
        const txns = snapshot.val();
        const payments = mapValues(txns, (txn) => {
          const { customerId } = txn;
          const posted = has(postedPayments, txn.id);
          const linked = find(
            users,
            (user) => user.squareCustomerId === customerId,
          );
          return {
            ...txn,
            firstName: linked?.firstName,
            lastName: linked?.lastName,
            posted,
          };
        });
        return dispatch(listPayments(payments));
      });
    } catch (error) {
      console.error("Error in fetchinSquarePayments:", error);
    }
  },
);

export const unfetchPaymentTransactions = createAsyncThunk(
  "payment/fetchPaymentTransactions",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      await off(ref(db, "squareTransactions"));
    } catch (error) {
      console.error("Error in unfetchinSquarePayments:", error);
    }
  },
);

const paymentSlice = createSlice({
  name: "payment",
  initialState: {
    loading: false,
    error: null,
    lastPaymentId: null,
    payments: {},
  },
  reducers: {
    listPayments: (state, action) => {
      state.error = null;
      state.payments = { ...action.payload };
    },
    clearPaymentError: (state) => {
      state.error = null;
    },
    clearTxnId: (state) => {
      state.lastPaymentId = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(processSquarePayment.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(processSquarePayment.fulfilled, (state, action) => {
        state.loading = false;
        state.lastPaymentId = action.payload.paymentId;
      })
      .addCase(processSquarePayment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(postSinglePayment.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(postSinglePayment.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(postSinglePayment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { clearPaymentError, clearTxnId, listPayments } =
  paymentSlice.actions;

export default paymentSlice.reducer;
